<template>
  <ApolloQuery
    :query="require('@/graphql/queries/AllEvents.gql')"
    :variables="{ type: checkedType }"
    @result="doneLoadingEvents"
  >
    <template slot-scope="{ result: { loading, error } }">
      <div class="modal" style="align-items: flex-start" v-if="showModal">
        <div class="modal__back" @click="showModal = false"></div>
        <div class="modal__body">
          <img :src="image" alt="">
        </div>
      </div>
      <div class="lsg-event">
        <div class="main__header">
          <div class="main__header__item left"></div>
          <div class="main__header__item">
            <h1>{{ title }}</h1>
          </div>
          <div class="main__header__item right"></div>
        </div>
        <div v-if="error" class="messages apollo error">
          <div class="messages__status error">{{ error.message }}</div>
        </div>
        <div v-if="loading && !error" class="messages__status loading">Идёт загрузка данных...</div>
        <div v-if="!error && !loading" class="main__content">
          <div class="event__bar">
          </div>
          <div class="event__nav">
            <a class="btn" :class="{'btn--orange': item.checked, ' btn--gray': !item.checked}" v-for="(item, index) in navs" :key="index" @click="onCheckType(item.field)">{{ item.label }}</a>
          </div>
          <div class="event__list">
            <div :id="elem.type" class="event__theme" v-for="(elem, index1) in events" :key="index1">
              <h2>{{ elem.type }}</h2>
              <div class="event__item" @click.self="item.expanded = !item.expanded" v-for="(item, index2) in elem.list" :key="index2">
                <i class="event__item__state">
                  <PlusIco v-if="!item.expanded"/>
                  <MinusIco v-else/>
                </i>
                <h3 @click.prevent="item.expanded = !item.expanded" :id="item.title" class="event__item__title">{{ item.title }}</h3>
                <h4 v-if="item.expanded" class="event__item__subtitle">{{ item.subtitle }}</h4>
                <div class="event__item__text" v-if="item.expanded" v-html="item.text"></div>
                <div :class="`event__item__gallery--${item.images.length}`" v-if="item.expanded && item.images && item.images.length > 0">
                  <figure v-for="(image, i) in item.images" :key="image" :class="`event__item__gallery--${item.images.length}__item event__item__gallery--${item.images.length}__item--${i + 1}`">
                    <img :src="`${baseUrl}/image?id=${image}`" :class="`event__item__gallery--${item.images.length}__img`" alt="" @click="onShowModal">
                  </figure>
                </div>
                <div v-if="item.expanded" class="event__item__date">{{ new Date(item.createdAt).toLocaleString('ru-RU') }}</div>
              </div>
            </div>
          </div>
          <div class="scrollToTop" @click="scrollToTop">
            <svg width="50" height="50" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 6L8 10L12 6" stroke="#FF8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import PlusIco from '@/components/svg/plus'
import MinusIco from '@/components/svg/minus'

export default {
  name: 'event',
  components: {
    MinusIco,
    PlusIco
  },
  data () {
    return {
      title: 'Новости',
      links: [
        {
          name: 'Главная',
          route: 'home'
        },
        {
          name: 'Часто задаваемые вопросы'
        }
      ],
      navs: [
        {
          label: 'Релизы',
          field: 'crm',
          checked: false
        },
        {
          label: 'Новости компании',
          field: 'news',
          checked: false
        },
        {
          label: 'Специальные предложения',
          field: 'offers',
          checked: false
        }
      ],
      baseUrl: process.env.VUE_APP_HTTP,
      events: [],
      defaultEvents: [],
      event: [],
      image: null,
      showModal: false,
      checkedType: null
    }
  },
  methods: {
    onCheckType (type) {
      if (this.checkedType === type) {
        this.checkedType = null
      } else this.checkedType = type

      this.navs.forEach((item, index) => {
        if (item.field === type) this.navs[index].checked = !this.navs[index].checked
        else this.navs[index].checked = false
      })
    },
    onShowModal (e) {
      this.showModal = true
      this.image = e.srcElement.currentSrc
    },
    doneLoadingEvents ({ data, loading }) {
      this.defaultEvents = data.allEvents
      let eventTypes = this.defaultEvents.map(elem => {
        return elem.type
      })
      eventTypes = [...new Set(eventTypes)]
      let obj = eventTypes.map(elem => {
        return {
          type: elem,
          list: []
        }
      })
      this.defaultEvents.forEach(eventElem => {
        obj.forEach(objElem => {
          if (eventElem.type === objElem.type) {
            objElem.list.push(
              {
                expanded: false,
                ...eventElem
              }
            )
          }
        })
      })

      obj = obj.map(elem => {
        let newElem = null
        switch (elem.type) {
          case 'news':
            newElem = 'Новости компании'
            break
          case 'crm':
            newElem = 'Релизы'
            break
          case 'offers':
            newElem = 'Специальные предложения'
            break
        }
        return {
          type: newElem,
          checked: elem.checked,
          list: elem.list
        }
      })
      this.events = obj
    },
    scroll (value) {
      let coordinates = document.getElementById(value).getBoundingClientRect()
      scrollTo({ top: coordinates.top - 100, behavior: 'smooth' })
    },
    scrollToTop () {
      scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="stylus">

.scrollToTop
  position fixed
  bottom 70px
  right 50px
  height 50px
  width 50px
  border-radius 50%
  z-index 3
  cursor pointer
  display flex
  justify-content center
  align-items center

  > svg
    transform-origin: center center
    transform rotate(180deg)

.event
  &__bar
    display flex
    justify-content space-between
    align-items center
    flex-wrap wrap

    &__search {
      +below(768px) {
        position absolute
        absolute left bottom
        left auto
        width 100%
        max-width 500px
        min-width auto
      }
      > .hs__section {
        padding-top 20px

        &__list li a {
          cursor pointer !important
        }
      }
    }

    +below(1040px)
      justify-content center

    &__links
      display flex
      justify-content space-between
      width 400px
      margin-bottom 20px

      +below(440px)
        height 100px
        flex-direction column
        align-items center
        justify-content space-between

    &__input
      max-width 500px
      width 100%
      margin 0 0 20px 0
      position relative

      i
        display flex
        align-items center
        absolute right 16px top bottom

  &__nav
    display flex
    min-height 60px
    flex-direction row
    justify-content flex-start
    flex-wrap wrap
    margin 10px 60px

    +below(440px)
      justify-content center

    > .btn
      height 40px !important
      margin-bottom 20px
      margin-right 10px

  &__list
    display flex
    flex-direction column
    align-items flex-start
    margin -8px 0
    padding 10px 60px

    +below(470px)
      padding 10px 10px

  &__theme
    display flex
    width 100%
    flex-direction column
    align-items flex-start

    &:not(:last-child)
      margin-bottom 30px

  &__item
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    padding 40px 80px 40px 40px
    width 100%
    max-width 1400px
    margin 8px 0
    position relative
    cursor pointer

    &:hover
      box-shadow: 0 10px 30px rgba($darker, 0.17);

    &__state
      absolute right 40px top 40px bottom
      width 24px
      height 24px
      display flex
      align-items center
      justify-content center
      flex-shrink 0

      svg
        width 100%
        height 100%

        path
          stroke $c7A

    &__title
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      margin 0
      color $orange

      +below(470px)
        font-size 14px

      +below(375px)
        font-size 12px

    &__subtitle, &__date
      font-style: italic;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      margin 10px 0 0 0
      color #7a7a7a

      +below(375px)
        font-size 12px

    &__date
      margin-top 20px
      font-size: 14px;

    &__text
      width 100%
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      margin-top 20px
      color $dark
      white-space pre-wrap

      > a
        color $orange
        z-index 2

        &:hover
          text-shadow 1px 1px 2px $orange

      +below(470px)
        font-size 14px

      +below(375px)
        font-size 12px

      ^[0]:hover
        color $dark

    &__gallery--1
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
      grid-gap: 0.5vw;
      max-width 450px
      margin-top 10px

      &__img
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        transition: 0.5s
        // filter blur(50%)

        &:hover
          transform scale(1.1)
          // filter blur()

      &__item--1 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
      }

    &__gallery--2
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, 1fr);
      grid-gap: 0.5vw;
      max-width 450px
      margin-top 10px

      &__img
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        transition: 0.5s
        // filter blur(50%)

        &:hover
          transform scale(1.1)
          // filter blur()

      &__item--1 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &__item--2 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
      }

    &__gallery--3
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 0.5vw;
      max-width 450px
      margin-top 10px

      &__img
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        transition: 0.5s
        // filter blur(50%)

        &:hover
          transform scale(1.1)
          // filter blur()

      &__item--1 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
      }
      &__item--2 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &__item--3 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
      }

    &__gallery--4
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-gap: 0.5vw;
      max-width 450px
      margin-top 10px

      &__img
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        transition: 0.5s
        // filter blur(50%)

        &:hover
          transform scale(1.1)
          // filter blur()

      &__item--1 {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 4;
      }
      &__item--2 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &__item--3 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 3;
      }
      &__item--4 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 3;
        grid-row-end: 4;
      }

    &__gallery--5
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(4, 1fr);
      grid-gap: 0.5vw;
      max-width 450px
      margin-top 10px

      &__img
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        transition: 0.5s
        // filter blur(50%)

        &:hover
          transform scale(1.1)
          // filter blur()

      &__item--1 {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 5;
      }
      &__item--2 {
        grid-column-start: 5;
        grid-column-end: 6;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &__item--3 {
        grid-column-start: 5;
        grid-column-end: 6;
        grid-row-start: 2;
        grid-row-end: 3;
      }
      &__item--4 {
        grid-column-start: 5;
        grid-column-end: 6;
        grid-row-start: 3;
        grid-row-end: 4;
      }
      &__item--5 {
        grid-column-start: 5;
        grid-column-end: 6;
        grid-row-start: 4;
        grid-row-end: 5;
      }

  &__answer
    color $black
</style>
